import * as ApiEndpointUtils from 'ApiEndpointUtils';
export async function get(path, options) {
    return performApiRequest(path, 'GET', /* body */ undefined, options);
}
export async function post(path, body, options) {
    return performApiRequest(path, 'POST', body, options);
}
async function performApiRequest(path, method, body, options) {
    const { headers, signal } = options;
    return fetch(`${ApiEndpointUtils.getApiEndpoint()}${path}`, {
        credentials: 'include',
        headers,
        method,
        signal,
        body: JSON.stringify(body),
    });
}
export async function assertStatus(response, ...expected) {
    if (expected.includes(response.status)) {
        return;
    }
    let message = `got unexpected status code ${response.status}`;
    if (response.headers.has('x-subtrace-id')) {
        message = `${message} (x-subtrace-id: ${response.headers.get('x-subtrace-id')})`;
    }
    try {
        const data = await response.json();
        if (data.error) {
            message = `${message}: ${data.error}`;
        }
    }
    catch (error) {
        // Parsing the response body as a JSON isn't always possible.
    }
    throw new Error(message);
}
