import React from 'react';
import { Link } from 'react-router-dom';
import * as StyleUtils from 'Utils/StyleUtils';
export function InternalLink(props) {
    return (React.createElement(Link, { className: StyleUtils.mergeClassNames(props.showAsButton
            ? 'outline outline-2 outline-sky-900/60 text-sm text-white font-medium rounded-md bg-sky-700/95 hover:bg-sky-700 group'
            : 'rounded font-medium text-sm text-zinc-300/95 hover:text-zinc-300', props.className), to: props.target },
        props.label,
        props.showArrow ? (React.createElement("span", { className: "inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]" }, "\u2192")) : null));
}
