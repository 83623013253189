import React from 'react';
import * as StyleUtils from 'Utils/StyleUtils';
export function ExternalLink(props) {
    return (React.createElement("a", Object.assign({ className: StyleUtils.mergeClassNames(props.showAsButton
            ? 'outline outline-2 outline-sky-900/60 text-sm text-white font-medium rounded-md bg-sky-700/95 hover:bg-sky-700 group'
            : 'rounded font-medium text-sm text-zinc-300/95 hover:text-zinc-300', props.className), href: props.target }, (props.openInNewTab
        ? {
            target: '_blank',
            rel: 'noreferrer',
        }
        : {})),
        props.label,
        props.showArrow ? (React.createElement("span", { className: "inline-block ml-2 transition ease-in-out group-hover:translate-x-[2px]" }, "\u2192")) : null));
}
