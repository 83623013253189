import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { AppMain } from './AppMain';
import { LoginPage } from './LoginPage';
import { LandingPage } from 'LandingPage';
import './style.css';
const rootElement = document.getElementById('root');
createRoot(rootElement).render(React.createElement(React.StrictMode, null,
    React.createElement(BrowserRouter, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(LandingPage, null) }),
            React.createElement(Route, { path: "/dashboard", element: React.createElement(AppMain, null) }),
            React.createElement(Route, { path: "/dashboard/*", element: React.createElement(AppMain, null) }),
            React.createElement(Route, { path: "/login", element: React.createElement(LoginPage, null) })))));
