import * as Popover from '@radix-ui/react-popover';
import * as React from 'react';
import { PlusIcon, XCircleIcon, XIcon } from 'lucide-react';
import * as BigIntUtils from 'BigIntUtils';
import * as NumericalFilterUtils from 'NumericalFilterUtils';
import * as ResultUtils from 'Utils/ResultUtils';
import * as StyleUtils from 'Utils/StyleUtils';
import * as Verify from 'Utils/Verify';
import { Button } from 'DesignComponents/Button';
import { NumericalFilterKind } from 'NumericalFilter';
import { Select } from './DesignComponents/Select';
import { TextField } from 'DesignComponents/TextField';
export function NumericalFilterButton(props) {
    var _a, _b;
    const [isPopoverContentVisible, setIsPopoverContentVisible] = React.useState(false);
    const [filterKind, setFilterKind] = React.useState((_b = (_a = props.initialFilter) === null || _a === void 0 ? void 0 : _a.filterKind) !== null && _b !== void 0 ? _b : NumericalFilterKind.Equals);
    const { comparisonValue: initialComparisonValueInput, lowerBound: initialLowerBoundInput, upperBound: initialUpperBoundInput, } = NumericalFilterUtils.getFilterStringValues(props.initialFilter);
    const [comparisonValueInput, setComparisonValueInput] = React.useState(initialComparisonValueInput);
    const [lowerBoundInput, setLowerBoundInput] = React.useState(initialLowerBoundInput);
    const [upperBoundInput, setUpperBoundInput] = React.useState(initialUpperBoundInput);
    const [valueErrorMessage, setValueErrorMessage] = React.useState(undefined);
    const [lowerBoundErrorMessage, setLowerBoundErrorMessage] = React.useState(undefined);
    const [upperBoundErrorMessage, setUpperBoundErrorMessage] = React.useState(undefined);
    const filterSelectItems = getFilterSelectItems();
    const hasActiveFilter = !!props.initialFilter;
    return (React.createElement(Popover.Root, { open: isPopoverContentVisible, onOpenChange: setIsPopoverContentVisible },
        React.createElement(Popover.Trigger, { asChild: true, className: props.className },
            React.createElement("button", { "aria-label": props.filterParameterName, className: StyleUtils.mergeClassNames(`inline-flex items-center justify-center rounded-full pl-1 pr-2 border text-white border-white bg-transparent ${hasActiveFilter ? 'border-solid' : 'border-dashed'}`, 'hover:border-gray-900  hover:bg-white hover:text-gray-900') },
                renderIcon(),
                React.createElement("span", { className: "ml-1" }, props.filterParameterName))),
        React.createElement(Popover.Portal, null,
            React.createElement(Popover.Content, { className: "rounded p-5 pt-8 w-[260px] bg-gray-900 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade flex flex-col", sideOffset: 5, style: { zIndex: props.zIndex } },
                React.createElement(Select, { ariaLabel: "Filter kind", className: "w-full mb-2 h-6", items: filterSelectItems, onChange: onFilterKindChange, value: filterKind, zIndex: props.zIndex ? props.zIndex + 1 : undefined }),
                renderFormContent(),
                React.createElement(Button, { className: "mt-4 h-8", disabled: !!valueErrorMessage || !!lowerBoundErrorMessage || !!upperBoundErrorMessage, label: "Apply", onClick: onClickApply }),
                React.createElement(Popover.Close, { className: StyleUtils.mergeClassNames('rounded-full h-5 w-5 inline-flex items-center justify-center absolute top-1 right-1 outline-none cursor-default text-white', 'hover:bg-white hover:text-gray-900'), "aria-label": "Close" },
                    React.createElement(XIcon, { className: "h-4 w-4" })),
                React.createElement(Popover.Arrow, { className: "fill-white" })))));
    function clearFilter() {
        setIsPopoverContentVisible(false);
        setValueErrorMessage(undefined);
        props.onFilterCommitted(undefined);
    }
    function getFilterSelectItems() {
        return [
            {
                displayElement: 'Between',
                itemKey: NumericalFilterKind.Between,
                value: NumericalFilterKind.Between,
            },
            {
                displayElement: 'Equals',
                itemKey: NumericalFilterKind.Equals,
                value: NumericalFilterKind.Equals,
            },
            {
                displayElement: 'Greater than',
                itemKey: NumericalFilterKind.GreaterThan,
                value: NumericalFilterKind.GreaterThan,
            },
            {
                displayElement: 'Greater than or equal to',
                itemKey: NumericalFilterKind.GreaterThanOrEquals,
                value: NumericalFilterKind.GreaterThanOrEquals,
            },
            {
                displayElement: 'Lesser than',
                itemKey: NumericalFilterKind.LesserThan,
                value: NumericalFilterKind.LesserThan,
            },
            {
                displayElement: 'Lesser than or equal to',
                itemKey: NumericalFilterKind.LesserThanOrEquals,
                value: NumericalFilterKind.LesserThanOrEquals,
            },
            {
                displayElement: 'Does not equal',
                itemKey: NumericalFilterKind.NotEquals,
                value: NumericalFilterKind.NotEquals,
            },
        ];
    }
    function getNumericalFilter() {
        switch (filterKind) {
            case NumericalFilterKind.Between: {
                const lowerBound = BigIntUtils.parse(lowerBoundInput);
                const upperBound = BigIntUtils.parse(upperBoundInput);
                if (ResultUtils.isFailure(lowerBound) || ResultUtils.isFailure(upperBound)) {
                    return ResultUtils.failure({
                        lowerBoundErrorMessage: ResultUtils.isFailure(lowerBound)
                            ? 'Value must be an integer'
                            : undefined,
                        upperBoundErrorMessage: ResultUtils.isFailure(upperBound)
                            ? 'Value must be an integer'
                            : undefined,
                        valueErrorMessage: undefined,
                    });
                }
                return ResultUtils.success({
                    filterKind,
                    lowerBound: lowerBound.value,
                    upperBound: upperBound.value,
                });
            }
            case NumericalFilterKind.Equals:
            case NumericalFilterKind.GreaterThan:
            case NumericalFilterKind.GreaterThanOrEquals:
            case NumericalFilterKind.LesserThan:
            case NumericalFilterKind.LesserThanOrEquals:
            case NumericalFilterKind.NotEquals: {
                const comparisonValue = BigIntUtils.parse(comparisonValueInput);
                if (ResultUtils.isSuccess(comparisonValue)) {
                    return ResultUtils.success({
                        filterKind,
                        filterValue: comparisonValue.value,
                    });
                }
                return ResultUtils.failure({
                    lowerBoundErrorMessage: undefined,
                    upperBoundErrorMessage: undefined,
                    valueErrorMessage: 'Value must be an integer',
                });
            }
            default:
                Verify.isNever(filterKind);
        }
    }
    function onClickApply() {
        const result = getNumericalFilter();
        if (ResultUtils.isSuccess(result)) {
            setIsPopoverContentVisible(false);
            props.onFilterCommitted(result.value);
        }
        else if (ResultUtils.isFailure(result)) {
            setValueErrorMessage(result.error.valueErrorMessage);
            setLowerBoundErrorMessage(result.error.lowerBoundErrorMessage);
            setUpperBoundErrorMessage(result.error.upperBoundErrorMessage);
        }
        else {
            Verify.isNever(result);
        }
    }
    function onFilterKindChange(_filterKind) {
        setFilterKind(_filterKind);
        setValueErrorMessage(undefined);
    }
    function onLowerBoundChange(lowerBound) {
        setLowerBoundInput(lowerBound);
        setLowerBoundErrorMessage(undefined);
    }
    function onUpperBoundChange(upperBound) {
        setUpperBoundInput(upperBound);
        setUpperBoundErrorMessage(undefined);
    }
    function onValueChange(filterValue) {
        setComparisonValueInput(filterValue);
        setValueErrorMessage(undefined);
    }
    function renderFormContent() {
        switch (filterKind) {
            case NumericalFilterKind.Between:
                return (React.createElement("div", null,
                    React.createElement(TextField, { classNames: { root: 'w-full mb-1', input: 'h-6 px-1' }, errorMessage: lowerBoundErrorMessage, label: "Minimum", onChange: onLowerBoundChange, value: lowerBoundInput }),
                    React.createElement(TextField, { classNames: { root: 'w-full', input: 'h-6 px-1' }, errorMessage: upperBoundErrorMessage, label: "Maximum", onChange: onUpperBoundChange, value: upperBoundInput })));
            case NumericalFilterKind.Equals:
            case NumericalFilterKind.GreaterThan:
            case NumericalFilterKind.GreaterThanOrEquals:
            case NumericalFilterKind.LesserThan:
            case NumericalFilterKind.LesserThanOrEquals:
            case NumericalFilterKind.NotEquals:
                return (React.createElement("div", null,
                    React.createElement(TextField, { classNames: { root: 'w-full', input: 'h-6 px-1' }, errorMessage: valueErrorMessage, onChange: onValueChange, value: comparisonValueInput })));
            default:
                Verify.isNever(filterKind);
        }
    }
    function renderIcon() {
        return hasActiveFilter ? (React.createElement("span", { className: "hover:text-red-600", onClick: (event) => {
                clearFilter();
                event.stopPropagation();
            } },
            React.createElement(XCircleIcon, { className: "h-4 w-4" }))) : (React.createElement("span", null,
            React.createElement(PlusIcon, { className: "h-4 w-4" })));
    }
}
