import * as React from 'react';
import { Loader2, Trash } from 'lucide-react';
import * as ApiUtils from 'ApiUtils';
function List(props) {
    if (props.tokens === null) {
        return (React.createElement("div", { className: "pt-4 w-full flex items-center" },
            React.createElement("div", { className: "w-full h-full flex justify-center items-center rounded-md text-zinc-600" },
                React.createElement("div", { className: "w-4 h-4 flex justify-center items-center" },
                    React.createElement("span", { className: "w-[14px] h-[14px] flex items-center animate-spin-loader" },
                        React.createElement(Loader2, null))))));
    }
    const deleteToken = async (tokenId) => {
        const response = await ApiUtils.post('/api/DeleteToken', { tokenId }, {});
        await ApiUtils.assertStatus(response, 200);
        await props.refreshTokens();
    };
    return (React.createElement("div", { className: "rounded overflow-hidden border border-zinc-800/80 w-full flex flex-col divide-y divide-zinc-800/80" }, props.tokens.map(({ tokenId, createTime, name, creatorEmail }) => {
        const diff = ((Date.now() - (new Date(createTime)).getTime())) / 1000;
        let count, unit;
        if (diff < 60)
            [count, unit] = [Math.round(diff), "second"];
        else if (diff < 60 * 60)
            [count, unit] = [Math.round(diff / 60), "minute"];
        else if (diff < 24 * 60 * 60)
            [count, unit] = [Math.round(diff / 60 / 60), "hour"];
        else
            [count, unit] = [Math.round(diff / 60 / 60 / 24), "day"];
        if (count != 1)
            unit += "s";
        return (React.createElement("div", { className: "w-full px-4 py-2 flex justify-between items-center hover:bg-zinc-900 group relative", key: tokenId },
            React.createElement("div", { className: "font-mono text-zinc-300 text-sm truncated text-ellipsis" }, name),
            React.createElement("div", { className: "flex flex-col space-y-[1px] items-end group-hover:opacity-0" },
                React.createElement("div", { className: "font-medium text-zinc-600 text-[10px]" }, creatorEmail),
                React.createElement("div", { title: createTime, className: "text-zinc-600/80 text-[10px]" },
                    count,
                    " ",
                    unit,
                    " ago")),
            React.createElement("button", { onClick: () => deleteToken(tokenId), title: "Delete", className: "hidden absolute top-0 right-0 w-16 cursor-pointer group-hover:flex justify-center items-center h-full bg-gradient-to-l from-red-900/20 hover:from-red-900/30 to-transparent text-red-600 hover:text-red-400" },
                React.createElement("span", { className: "w-[14px] h-[14px] flex items-center" },
                    React.createElement(Trash, null)))));
    })));
}
export function TokensPage() {
    const projectId = '9eda2a98-6006-4c7e-bfd5-904cae36c308'; // TODO: use a project ID app state
    const [tokens, setTokens] = React.useState(null);
    const refreshTokens = async () => {
        const response = await ApiUtils.post('/api/ListTokens', { projectId }, {});
        await ApiUtils.assertStatus(response, 200);
        const { tokens } = await response.json();
        setTokens(tokens);
    };
    React.useEffect(() => {
        refreshTokens();
    }, []);
    const create = async () => {
        const response = await ApiUtils.post('/api/GenerateToken', { projectId }, {});
        await ApiUtils.assertStatus(response, 200);
        const { token } = await response.json();
        console.log(token);
        refreshTokens();
    };
    return (React.createElement("div", { className: "w-full flex justify-center" },
        React.createElement("div", { className: "w-full max-w-xl px-8 py-12" },
            React.createElement("div", { className: "flex flex-col space-y-8" },
                React.createElement("div", { className: "flex flex-col space-y-4" },
                    React.createElement("div", { className: "flex items-center justify-between space-x-2" },
                        React.createElement("div", { className: "text-xs text-zinc-500 max-w-80" },
                            "Subtrace API tokens are used by clients to authenticate when pushing or querying tracing data.",
                            " ",
                            React.createElement("a", { className: "brightness-[1.3] hover:brightness-[1.5]", href: "https://docs.subtrace.dev" }, "Learn more.")),
                        React.createElement("button", { className: "rounded px-4 py-[6px] font-medium text-xs bg-sky-900 hover:brightness-[1.1] text-zinc-200 flex space-x-1", onClick: create },
                            React.createElement("span", null, "Create API token"),
                            React.createElement("span", null, "\u2192")))),
                React.createElement(List, { tokens: tokens, refreshTokens: refreshTokens })))));
}
