import * as React from 'react';
import * as StyleUtils from 'Utils/StyleUtils';
export function TextField(props) {
    var _a, _b;
    return (React.createElement("div", { className: StyleUtils.mergeClassNames('flex flex-col', (_a = props.classNames) === null || _a === void 0 ? void 0 : _a.root) },
        props.label ? React.createElement("span", { className: "text-white text-sm" }, props.label) : null,
        React.createElement("input", { className: StyleUtils.mergeClassNames('rounded-md bg-slate-200 cursor-pointer hover:brightness-[1.05] px-4 py-2 text-sm outline outline-2 outline-zinc-600/60 active:outline-zinc-600/80 focus:outline-zinc-600/70', (_b = props.classNames) === null || _b === void 0 ? void 0 : _b.input), disabled: props.disabled, onChange: onValueChange, placeholder: props.placeholder, type: props.type, value: props.value }),
        props.errorMessage ? React.createElement("span", { className: "text-red-600 text-xs" }, props.errorMessage) : null));
    function onValueChange(event) {
        props.onChange(event.target.value);
    }
}
