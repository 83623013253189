import * as React from 'react';
import * as Popover from '@radix-ui/react-popover';
import { PlusIcon, XCircleIcon, XIcon } from 'lucide-react';
import { Button } from 'DesignComponents/Button';
import { Select } from './DesignComponents/Select';
import { TextField } from 'DesignComponents/TextField';
import { TextFilterKind } from 'TextFilter';
import * as ResultUtils from 'Utils/ResultUtils';
import * as StyleUtils from 'Utils/StyleUtils';
import * as TextFilterUtils from 'TextFilterUtils';
import * as Verify from 'Utils/Verify';
// TODO: Consolidate code between this file and MultiSelectFilterButton
export function TextFilterButton(props) {
    var _a, _b;
    const [isPopoverContentVisible, setIsPopoverContentVisible] = React.useState(false);
    const [filterKind, setFilterKind] = React.useState((_b = (_a = props.initialFilter) === null || _a === void 0 ? void 0 : _a.filterKind) !== null && _b !== void 0 ? _b : TextFilterKind.Contains);
    const [filterValue, setFilterValue] = React.useState(props.initialFilter ? TextFilterUtils.getFilterValue(props.initialFilter) : '');
    const [errorMessage, setErrorMessage] = React.useState(undefined);
    const filterSelectItems = getFilterSelectItems();
    const hasActiveFilter = !!props.initialFilter;
    return (React.createElement(Popover.Root, { open: isPopoverContentVisible, onOpenChange: setIsPopoverContentVisible },
        React.createElement(Popover.Trigger, { asChild: true, className: props.className },
            React.createElement("button", { "aria-label": props.filterParameterName, className: StyleUtils.mergeClassNames(`inline-flex items-center justify-center  rounded-full pl-1 pr-2 border text-white border-white bg-transparent ${hasActiveFilter ? 'border-solid' : 'border-dashed'}`, 'hover:border-gray-900  hover:bg-white hover:text-gray-900') },
                renderIcon(),
                React.createElement("span", { className: "ml-1" }, props.filterParameterName))),
        React.createElement(Popover.Portal, null,
            React.createElement(Popover.Content, { className: "rounded p-5 pt-8 w-[260px] bg-gray-900 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade flex flex-col", sideOffset: 5, style: { zIndex: props.zIndex } },
                React.createElement(Select, { ariaLabel: "Filter kind", className: "w-full mb-3 h-6", items: filterSelectItems, onChange: onFilterKindChange, value: filterKind, zIndex: props.zIndex ? props.zIndex + 1 : undefined }),
                filterKind !== TextFilterKind.IsEmpty && filterKind !== TextFilterKind.IsNotEmpty ? (React.createElement("div", null,
                    React.createElement(TextField, { classNames: { root: 'w-full', input: 'h-6 px-1' }, value: filterValue, onChange: onFilterValueChange }),
                    React.createElement("span", { className: "text-red-600 text-xs" }, errorMessage))) : null,
                React.createElement(Button, { className: "mt-5 h-8", disabled: !!errorMessage, label: "Apply", onClick: onClickApply }),
                React.createElement(Popover.Close, { className: StyleUtils.mergeClassNames('rounded-full h-5 w-5 inline-flex items-center justify-center absolute top-1 right-1 outline-none cursor-default text-white', 'hover:bg-white hover:text-gray-900'), "aria-label": "Close" },
                    React.createElement(XIcon, { className: "h-4 w-4" })),
                React.createElement(Popover.Arrow, { className: "fill-white" })))));
    function clearFilter() {
        setIsPopoverContentVisible(false);
        setErrorMessage(undefined);
        props.onFilterCommitted(undefined);
    }
    function getFilterSelectItems() {
        return [
            {
                displayElement: 'Contains',
                itemKey: TextFilterKind.Contains.toString(),
                value: TextFilterKind.Contains,
            },
            {
                displayElement: 'Does not contain',
                itemKey: TextFilterKind.DoesNotContain.toString(),
                value: TextFilterKind.DoesNotContain,
            },
            {
                displayElement: 'Does not end with',
                itemKey: TextFilterKind.DoesNotEndWith.toString(),
                value: TextFilterKind.DoesNotEndWith,
            },
            {
                displayElement: 'Does not equal',
                itemKey: TextFilterKind.DoesNotEqual.toString(),
                value: TextFilterKind.DoesNotEndWith,
            },
            {
                displayElement: 'Does not start with',
                itemKey: TextFilterKind.DoesNotStartWith.toString(),
                value: TextFilterKind.DoesNotEndWith,
            },
            {
                displayElement: 'Ends with',
                itemKey: TextFilterKind.EndsWith.toString(),
                value: TextFilterKind.EndsWith,
            },
            {
                displayElement: 'Equals',
                itemKey: TextFilterKind.Equals.toString(),
                value: TextFilterKind.Equals,
            },
            {
                displayElement: 'Is empty',
                itemKey: TextFilterKind.IsEmpty.toString(),
                value: TextFilterKind.IsEmpty,
            },
            {
                displayElement: 'Is not empty',
                itemKey: TextFilterKind.IsNotEmpty.toString(),
                value: TextFilterKind.IsNotEmpty,
            },
            {
                displayElement: 'Starts with',
                itemKey: TextFilterKind.StartsWith.toString(),
                value: TextFilterKind.StartsWith,
            },
        ];
    }
    function getTextFilter() {
        if (filterKind === TextFilterKind.IsEmpty || filterKind === TextFilterKind.IsNotEmpty) {
            return ResultUtils.success({ filterKind, filterValue: '' });
        }
        else if (filterValue.length !== 0) {
            return ResultUtils.success({ filterKind, filterValue });
        }
        else {
            return ResultUtils.failure('Please enter a value.');
        }
    }
    function onClickApply() {
        const result = getTextFilter();
        if (ResultUtils.isSuccess(result)) {
            setIsPopoverContentVisible(false);
            props.onFilterCommitted(result.value);
        }
        else if (ResultUtils.isFailure(result)) {
            setErrorMessage(result.error);
        }
        else {
            Verify.isNever(result);
        }
    }
    function onFilterKindChange(_filterKind) {
        setFilterKind(_filterKind);
        setErrorMessage(undefined);
    }
    function onFilterValueChange(_filterValue) {
        setFilterValue(_filterValue);
        setErrorMessage(undefined);
    }
    function renderIcon() {
        return hasActiveFilter ? (React.createElement("span", { className: "hover:text-red-600", onClick: (event) => {
                clearFilter();
                event.stopPropagation();
            } },
            React.createElement(XCircleIcon, { className: "h-4 w-4" }))) : (React.createElement("span", null,
            React.createElement(PlusIcon, { className: "h-4 w-4" })));
    }
}
