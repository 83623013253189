import { TextFilterKind } from 'TextFilter';
import * as Verify from 'Utils/Verify';
export function getFilterValue(filter) {
    switch (filter.filterKind) {
        case TextFilterKind.Contains:
        case TextFilterKind.DoesNotContain:
        case TextFilterKind.DoesNotEndWith:
        case TextFilterKind.DoesNotEqual:
        case TextFilterKind.DoesNotStartWith:
        case TextFilterKind.EndsWith:
        case TextFilterKind.Equals:
        case TextFilterKind.StartsWith:
            return filter.filterValue;
        case TextFilterKind.IsEmpty:
        case TextFilterKind.IsNotEmpty:
            return '';
        default:
            Verify.isNever(filter);
    }
}
