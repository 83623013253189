import * as React from 'react';
import { User, Loader2, Ellipsis } from 'lucide-react';
import * as ApiUtils from 'ApiUtils';
function Row(props) {
    const joinTime = new Date(props.user.joinTime);
    const [isOpen, setOpen] = React.useState(false);
    const ref = React.createRef();
    React.useEffect(() => {
        if (!isOpen) {
            return;
        }
        const handler = (ev) => {
            if (ref.current && !ref.current.contains(ev.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => document.removeEventListener("mousedown", handler);
    }, [ref]);
    const removeUser = async () => {
        const projectId = '9eda2a98-6006-4c7e-bfd5-904cae36c308'; // TODO: use a project ID app state
        const response = await ApiUtils.post('/api/RemoveUser', { projectId, userId: props.user.userId }, {});
        await ApiUtils.assertStatus(response, 200);
        setOpen(false);
        props.refreshUsers();
    };
    return (React.createElement("div", { className: "w-full py-2 flex items-center grid grid-cols-12 hover:bg-zinc-900/25 group" },
        React.createElement("div", { className: "px-2 col-span-8 text-zinc-300 text-xs truncated text-ellipsis flex items-center space-x-2" },
            React.createElement("span", { className: "w-[14px] h-[14px] flex items-center" },
                React.createElement(User, null)),
            React.createElement("span", null, props.user.email)),
        React.createElement("div", { title: joinTime.toISOString(), className: "px-2 col-span-3 text-zinc-400 text-xs" }, joinTime.toDateString()),
        React.createElement("div", { className: "px-2 col-span-1 text-zinc-400 flex justify-center items-center relative" },
            React.createElement("button", { onClick: () => setOpen(isOpen => !isOpen), className: [
                    "px-2 py-1 rounded",
                    isOpen ? "bg-zinc-900 hover:bg-zinc-900" : "hover:bg-zinc-900/75",
                ].join(" ") },
                React.createElement("span", { className: "w-[14px] h-[14px] flex items-center" },
                    React.createElement(Ellipsis, null))),
            isOpen ? (React.createElement("div", { ref: ref, className: "z-10 cursor-default rounded absolute -bottom-1 translate-y-[100%] right-2 py-1 bg-zinc-900 border border-[1px] border-zinc-700/80 outline outline-[2px] outline-black flex flex-col" },
                React.createElement("button", { className: "px-6 py-[6px] text-xs text-red-400 hover:bg-zinc-800/70", onClick: () => removeUser() }, "Remove"))) : null)));
}
function List(props) {
    if (props.users === null) {
        return (React.createElement("div", { className: "pt-4 w-full flex items-center" },
            React.createElement("div", { className: "w-full h-full flex justify-center items-center rounded-md text-zinc-600" },
                React.createElement("div", { className: "w-4 h-4 flex justify-center items-center" },
                    React.createElement("span", { className: "w-[14px] h-[14px] flex items-center animate-spin-loader" },
                        React.createElement(Loader2, null))))));
    }
    return (React.createElement("div", { className: "w-full z-0" },
        React.createElement("div", { className: "flex mb-4" },
            React.createElement("span", { className: "px-3 py-1 bg-zinc-800/80 rounded-full text-zinc-500 text-[10px] font-semibold" },
                props.users.length,
                " ",
                props.users.length == 1 ? "user" : "users")),
        React.createElement("div", { className: "w-full flex flex-col divide-y divide-zinc-900" },
            React.createElement("div", { className: "rounded w-full py-2 flex items-center grid grid-cols-12" },
                React.createElement("div", { className: "px-2 col-span-8 text-zinc-500/80 text-[10px] uppercase font-semibold" }, "Email"),
                React.createElement("div", { className: "px-2 col-span-3 text-zinc-500/80 text-[10px] uppercase font-semibold" }, "Joined")),
            props.users.map(user => React.createElement(Row, { key: user.userId, user: user, refreshUsers: props.refreshUsers })))));
}
export function UsersPage() {
    const projectId = '9eda2a98-6006-4c7e-bfd5-904cae36c308'; // TODO: use a project ID app state
    const [users, setUsers] = React.useState(null);
    const refreshUsers = async () => {
        const response = await ApiUtils.post('/api/ListUsers', { projectId }, {});
        await ApiUtils.assertStatus(response, 200);
        const { users } = await response.json();
        setUsers(users);
    };
    React.useEffect(() => {
        refreshUsers();
    }, []);
    return (React.createElement("div", { className: "w-full flex justify-center" },
        React.createElement("div", { className: "w-full max-w-xl px-8 py-12" },
            React.createElement("div", { className: "flex flex-col space-y-8" },
                React.createElement(List, { users: users, refreshUsers: refreshUsers })))));
}
