import * as React from 'react';
import * as ApiEndpointUtils from 'ApiEndpointUtils';
import { SignInButton } from 'SignInButton';
export function LoginPage() {
    return (React.createElement("div", { className: "w-dvw h-dvh flex flex-col" },
        React.createElement("div", { className: "flex grow justify-center items-center" },
            React.createElement("div", { className: "h-3/5 w-1/5 flex flex-col align-middle justify-between border border-white rounded-lg" },
                React.createElement(SubtraceIcon, { className: "mt-6" }),
                React.createElement("div", { className: "flex flex-col py-10 px-7 space-y-2" },
                    React.createElement(SignInButton, { className: "rounded-sm bg-white py-2", label: "Log in with Google", onClick: () => login('/api/GoogleRedirect') }),
                    React.createElement(SignInButton, { className: "rounded-sm bg-white py-2", label: "Log in with Github", onClick: () => login('/api/GithubRedirect') }))))));
    function login(path) {
        window.location.href = ApiEndpointUtils.getApiEndpoint() + path;
    }
}
function SubtraceIcon(props) {
    return (React.createElement("svg", { className: props.className, strokeMiterlimit: "10", style: {
            fillRule: 'nonzero',
            clipRule: 'evenodd',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
        }, version: "1.1", viewBox: "100 0 2200 340", xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("defs", null),
        React.createElement("g", { id: "Layer-1" },
            React.createElement("g", { fill: "#ffffff", opacity: "1", stroke: "none" },
                React.createElement("path", { d: "M311.25 180.375C311.25 201.625 324.375 215.125 350.25 220.75L370.875 225.125C385.375 228.25 390.625 232 390.625 239.75C390.625 248.75 381.625 254.625 367.625 254.625C353.375 254.625 344.125 249.375 342 239.875L308.25 239.875C310.125 265.75 331.875 280.875 367.125 280.875C403 280.875 425.75 263.25 425.75 235.375C425.75 214.375 413.375 202.5 385.5 196.75L364.125 192.375C350.75 189.625 345 185.125 345 177.625C345 168.875 353.625 163 366.625 163C379.375 163 388.375 168.625 390 177.75L422.25 177.75C420.625 151.875 400.25 136.875 366.875 136.875C332.75 136.875 311.25 153.625 311.25 180.375Z" }),
                React.createElement("path", { d: "M586.625 140.125L552.5 140.125L552.5 219.875C552.5 238.875 541.125 251 523.625 251C506.25 251 498.125 241.375 498.125 221.125L498.125 140.125L463.875 140.125L463.875 229.375C463.875 263 479.875 281 509.875 281C529.75 281 544.625 271.5 551.375 254.875L553.5 254.875L553.5 278L586.625 278L586.625 140.125Z" }),
                React.createElement("path", { d: "M698.75 280.125C730.375 280.125 749 258.375 749 220.875L749 197.125C749 159.625 730.375 137.875 698.75 137.875C679 137.875 665.25 147.125 660.625 163.625L658 163.625L658 87.625L622.375 87.625L622.375 278L657.625 278L657.625 254.25L660.125 254.25C665 271 678.625 280.125 698.75 280.125ZM685.625 250.5C668 250.5 657.125 238.875 657.125 219.875L657.125 197.125C657.125 178.125 668 166.625 685.625 166.625C703.375 166.625 714.125 178.125 714.125 197.125L714.125 219.875C714.125 238.875 703.375 250.5 685.625 250.5Z" }),
                React.createElement("path", { d: "M813.25 100.75L813.25 141.5L777.375 141.5L777.375 168.875L813.25 168.875L813.25 234.375C813.25 266.375 827.625 278.25 865.125 278.25C867.875 278.25 895.125 278 896.5 277.75L896.5 250.75C895 250.875 871.125 251 867.875 251C854.75 251 847.375 245.125 847.375 234.75L847.375 168.875L898.125 168.875L898.125 141.5L846.75 141.5L846.75 100.75L813.25 100.75Z" }),
                React.createElement("path", { d: "M997.375 216.875C997.375 187.25 1013.25 172.5 1045.75 172.5C1053.5 172.5 1062.5 174.125 1068.75 176.5L1068.75 139.375C1063.88 137.5 1056.88 136.5 1049.12 136.5C1023.12 136.5 1009 148.125 1003 175.5L997.625 175.5L997.625 140.125L935.875 140.125L935.875 165.375L964.625 165.375L964.625 252.875L938.375 252.875L938.375 278L1037.38 278L1037.38 252.875L997.375 252.875L997.375 216.875Z" }),
                React.createElement("path", { d: "M1139.62 280.25C1157.62 280.25 1172.12 272.625 1178.88 259.625L1181.88 259.625L1181.88 278L1214.38 278L1214.38 184.5C1214.38 154.375 1194.5 137.5 1159.12 137.5C1126.38 137.5 1103.75 153.625 1101.25 178.625L1134.5 178.625C1137.38 170 1146.25 164.75 1158.12 164.75C1172.25 164.75 1180.5 171.75 1180.5 183.75L1180.5 194.75L1148.25 196.75C1114.25 199.125 1096.12 213.75 1096.12 238.625C1096.12 263.5 1113.62 280.25 1139.62 280.25ZM1151.38 252.875C1138.12 252.875 1129.88 246.375 1129.88 236.125C1129.88 225.5 1138 219.375 1154 218.375L1180.5 216.75L1180.5 226.75C1180.5 242.125 1168.38 252.875 1151.38 252.875Z" }),
                React.createElement("path", { d: "M1378.88 188.25C1375.62 154.5 1354.88 136.75 1319.25 136.75C1278.62 136.75 1256.12 159.75 1256.12 201.625L1256.12 216.125C1256.12 258 1278.62 281 1319.25 281C1354.88 281 1375.88 263.5 1378.88 230.625L1345.38 230.625C1343 245.375 1334 252.5 1319 252.5C1300.62 252.5 1290.5 238.75 1290.5 213.5L1290.5 204.25C1290.5 179 1300.62 165.25 1319 165.25C1333.88 165.25 1343.12 172.75 1345.38 188.25L1378.88 188.25Z" }),
                React.createElement("path", { d: "M1504.62 239.25C1502.12 248.25 1491.25 254.5 1477.5 254.5C1459.62 254.5 1448 242.75 1448 224.75L1448 217.25L1536.88 217.25L1536.88 198.25C1536.88 161.25 1513.5 137.25 1477 137.25C1439.5 137.25 1414.5 161.125 1414.5 197.25L1414.5 219.375C1414.5 258.5 1437.5 281 1477.62 281C1509.62 281 1534 263.625 1536.88 239.25L1504.62 239.25ZM1476.38 163.75C1493.62 163.75 1503.88 175.625 1503.88 195.375L1448 195.375C1448 175.625 1458.62 163.75 1476.38 163.75Z" }),
                React.createElement("path", { d: "M1697.88 325.5L1697.88 287.75L1568.12 287.75L1568.12 325.5L1697.88 325.5Z", fill: "#60a5fa" })),
            React.createElement("g", { fill: "#ef4444", opacity: "1", stroke: "none" },
                React.createElement("path", { d: "M1646.87 171.582C1659.52 171.582 1669.72 166.221 1674.46 157.08L1676.57 157.08L1676.57 170L1699.42 170L1699.42 104.258C1699.42 83.0762 1685.45 71.2109 1660.58 71.2109C1637.55 71.2109 1621.64 82.5488 1619.88 100.127L1643.26 100.127C1645.28 94.0625 1651.52 90.3711 1659.87 90.3711C1669.8 90.3711 1675.61 95.293 1675.61 103.73L1675.61 111.465L1652.93 112.871C1629.02 114.541 1616.28 124.824 1616.28 142.314C1616.28 159.805 1628.58 171.582 1646.87 171.582ZM1655.13 152.334C1645.81 152.334 1640.01 147.764 1640.01 140.557C1640.01 133.086 1645.72 128.779 1656.97 128.076L1675.61 126.934L1675.61 133.965C1675.61 144.775 1667.08 152.334 1655.13 152.334Z" }),
                React.createElement("path", { d: "M1814.03 170L1814.03 150.84L1785.12 150.84L1785.12 36.1426L1731.86 36.1426L1731.86 55.3027L1761.04 55.3027L1761.04 150.84L1730.27 150.84L1730.27 170L1814.03 170Z" }),
                React.createElement("path", { d: "M1893.31 71.5625C1879.16 71.5625 1869.58 78.0664 1866.15 89.7559L1864.31 89.7559L1864.31 73.0566L1839.61 73.0566L1839.61 202.256L1864.66 202.256L1864.66 153.564L1866.42 153.564C1869.67 165.166 1879.34 171.67 1893.31 171.67C1915.55 171.67 1928.64 156.377 1928.64 129.922L1928.64 113.223C1928.64 86.8555 1915.55 71.5625 1893.31 71.5625ZM1884.08 92.4805C1896.47 92.4805 1904.12 100.654 1904.12 113.926L1904.12 130.01C1904.12 143.281 1896.47 151.455 1884.08 151.455C1871.6 151.455 1864.04 143.369 1864.04 130.01L1864.04 113.926C1864.04 100.566 1871.6 92.4805 1884.08 92.4805Z" }),
                React.createElement("path", { d: "M1950.26 170L1974.26 170L1974.26 114.277C1974.26 100.918 1982.43 92.3047 1995.09 92.3047C2007.3 92.3047 2013.28 99.248 2013.28 113.398L2013.28 170L2037.36 170L2037.36 107.51C2037.36 84.1309 2025.76 71.2988 2004.76 71.2988C1990.61 71.2988 1979.8 77.9785 1975.14 89.5801L1973.73 89.5801L1973.73 36.1426L1950.26 36.1426L1950.26 170Z" }),
                React.createElement("path", { d: "M2091.94 171.582C2104.6 171.582 2114.8 166.221 2119.54 157.08L2121.65 157.08L2121.65 170L2144.5 170L2144.5 104.258C2144.5 83.0762 2130.53 71.2109 2105.65 71.2109C2082.63 71.2109 2066.72 82.5488 2064.96 100.127L2088.34 100.127C2090.36 94.0625 2096.6 90.3711 2104.95 90.3711C2114.88 90.3711 2120.68 95.293 2120.68 103.73L2120.68 111.465L2098.01 112.871C2074.1 114.541 2061.36 124.824 2061.36 142.314C2061.36 159.805 2073.66 171.582 2091.94 171.582ZM2100.21 152.334C2090.89 152.334 2085.09 147.764 2085.09 140.557C2085.09 133.086 2090.8 128.779 2102.05 128.076L2120.68 126.934L2120.68 133.965C2120.68 144.775 2112.16 152.334 2100.21 152.334Z" })))));
}
