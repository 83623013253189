import * as React from 'react';
import * as Verify from 'Utils/Verify';
import { MoveDownLeftIcon, MoveUpRightIcon } from 'lucide-react';
export function HttpIncomingOutgoingCellRenderer(params) {
    return React.createElement("span", { className: "h-full flex flex-row items-center" }, renderContent());
    function renderContent() {
        switch (params.value) {
            case true:
                return React.createElement(MoveUpRightIcon, { size: 20 });
            case false:
                return React.createElement(MoveDownLeftIcon, { size: 20 });
            case null:
            case undefined:
                return null;
            default:
                Verify.isNever(params.value);
        }
    }
}
