import * as RadixUISelect from '@radix-ui/react-select';
import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import * as MapUtils from 'Utils/MapUtils';
import * as StyleUtils from 'Utils/StyleUtils';
export function Select(props) {
    const itemsByKey = new Map(props.items.map((item) => [item.itemKey, item]));
    const itemsByValue = new Map(props.items.map((item) => [item.value, item]));
    return (React.createElement(RadixUISelect.Root, { value: MapUtils.getOrThrow(itemsByValue, props.value).itemKey, onValueChange: (selectedKey) => props.onChange(MapUtils.getOrThrow(itemsByKey, selectedKey).value) },
        React.createElement(RadixUISelect.Trigger, { className: StyleUtils.mergeClassNames('inline-flex items-center justify-between rounded px-1 text-[13px] leading-none gap-[5px] bg-transparent text-white border border-white', props.className), "aria-label": props.ariaLabel },
            React.createElement(RadixUISelect.Value, { placeholder: props.placeholder }),
            React.createElement(RadixUISelect.Icon, null,
                React.createElement(ChevronDownIcon, { className: "h-4 w-4" }))),
        React.createElement(RadixUISelect.Portal, null,
            React.createElement(RadixUISelect.Content, { className: "overflow-hidden bg-gray-900 text-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]", position: "popper", style: { zIndex: props.zIndex } },
                React.createElement(RadixUISelect.ScrollUpButton, { className: "flex items-center justify-center h-[25px] text-white cursor-default" },
                    React.createElement(ChevronUpIcon, { className: "h-4 w-4" })),
                React.createElement(RadixUISelect.Viewport, { className: "p-[5px]" }, props.items.map((item) => (React.createElement(RadixUISelect.Item, { className: StyleUtils.mergeClassNames('text-[13px] px-1 leading-none rounded-[3px] flex items-center h-[25px] l-1 relative select-none data-[disabled]:pointer-events-none'), key: item.itemKey, value: item.itemKey },
                    React.createElement(RadixUISelect.ItemText, null, item.displayElement))))),
                React.createElement(RadixUISelect.ScrollDownButton, { className: "flex items-center justify-center h-[25px] text-white cursor-default" },
                    React.createElement(ChevronDownIcon, { className: "h-4 w-4" }))))));
}
