import * as React from 'react';
import * as ReactRouterDom from 'react-router-dom';
import { AppContents } from 'AppContents';
import { TokensPage } from 'TokensPage';
import { UsersPage } from 'UsersPage';
import { Navigation } from 'Navigation';
import * as CustomHooks from 'CustomHooks';
export function AppMain() {
    const location = ReactRouterDom.useLocation();
    const navigate = ReactRouterDom.useNavigate();
    const isLoggedIn = CustomHooks.useIsLoggedIn();
    if (!isLoggedIn) {
        navigate('/login');
        return React.createElement("div", null);
    }
    if (location.pathname === '/dashboard' || location.pathname == '/dashboard/') {
        navigate('/dashboard/requests');
        return React.createElement("div", null);
    }
    let page;
    switch (location.pathname) {
        default:
            page = (React.createElement("div", { className: "font-mono text-zinc-100 p-6 text-xs" },
                "TODO: ",
                location.pathname));
            break;
        case '/dashboard/requests':
            page = React.createElement(AppContents, null);
            break;
        case '/dashboard/tokens':
            page = React.createElement(TokensPage, null);
            break;
        case '/dashboard/users':
            page = React.createElement(UsersPage, null);
            break;
    }
    return (React.createElement("div", { className: "w-screen h-screen flex space-x-4" },
        React.createElement(Navigation, null),
        React.createElement("div", { className: "basis-2/3 grow shrink max-w-[80%]" }, page)));
}
