export var TextFilterKind;
(function (TextFilterKind) {
    TextFilterKind["Contains"] = "Contains";
    TextFilterKind["DoesNotContain"] = "DoesNotContain";
    TextFilterKind["DoesNotEndWith"] = "DoesNotEndWith";
    TextFilterKind["DoesNotEqual"] = "DoesNotEqual";
    TextFilterKind["DoesNotStartWith"] = "DoesNotStartWith";
    TextFilterKind["EndsWith"] = "EndsWith";
    TextFilterKind["Equals"] = "Equals";
    TextFilterKind["IsEmpty"] = "IsEmpty";
    TextFilterKind["IsNotEmpty"] = "IsNotEmpty";
    TextFilterKind["StartsWith"] = "StartsWith";
})(TextFilterKind || (TextFilterKind = {}));
