import * as Popover from '@radix-ui/react-popover';
import * as React from 'react';
import { PlusIcon, XCircleIcon, XIcon } from 'lucide-react';
import * as StyleUtils from 'Utils/StyleUtils';
import { Button } from 'DesignComponents/Button';
import { Checkbox } from './DesignComponents/Checkbox';
export function MultiSelectFilterButton(props) {
    const [isPopoverContentVisible, setIsPopoverContentVisible] = React.useState(false);
    const [itemsAndCheckedStates, setItemsAndCheckedStates] = React.useState(getInitialItemsAndCheckedStates(props.items));
    const [prevItems, setPrevItems] = React.useState(null);
    // The checked items state needs to be kept in sync with `props.items`. If the value of `props.items` changes
    // (which could happen to the values in the grid changing), the checked items state needs to be effectively reset.
    if (prevItems !== props.items) {
        setItemsAndCheckedStates(getInitialItemsAndCheckedStates(props.items));
        setPrevItems(props.items);
    }
    const hasActiveFilter = !!props.initialFilter;
    return (React.createElement(Popover.Root, { open: isPopoverContentVisible, onOpenChange: setIsPopoverContentVisible },
        React.createElement(Popover.Trigger, { asChild: true, className: props.className },
            React.createElement("button", { "aria-label": props.filterParameterName, className: StyleUtils.mergeClassNames(`inline-flex items-center justify-center  rounded-full pl-1 pr-2 border text-white border-white bg-transparent ${hasActiveFilter ? 'border-solid' : 'border-dashed'}`, 'hover:border-gray-900  hover:bg-white hover:text-gray-900') },
                renderIcon(),
                React.createElement("span", { className: "ml-1" }, props.filterParameterName))),
        React.createElement(Popover.Portal, null,
            React.createElement(Popover.Content, { className: "rounded p-5 pt-8 w-[260px] bg-gray-900 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade flex flex-col", sideOffset: 5, style: { zIndex: props.zIndex } },
                renderSelectAllCheckbox(),
                React.createElement("div", { className: "h-[1px] bg-gray-300 my-2" }),
                itemsAndCheckedStates.map((item, index) => {
                    return (React.createElement(Checkbox, { classNames: {
                            checkbox: StyleUtils.mergeClassNames('bg-transparent border-white text-white', index !== 0 ? 'mt-1' : null),
                            label: 'text-white',
                        }, checked: item.checked, labelElement: React.createElement("span", { className: "flex flex-row items-center" },
                            item.displayElement,
                            React.createElement("span", { className: 'ml-1' }, item.itemCount !== undefined ? ` (${item.itemCount})` : '')), onCheckedChange: (checked) => onCheckedChange(index, checked), key: item.itemKey }));
                }),
                React.createElement(Button, { className: "mt-4 h-8", label: "Apply", onClick: onClickApply }),
                React.createElement(Popover.Close, { className: StyleUtils.mergeClassNames('rounded-full h-5 w-5 inline-flex items-center justify-center absolute top-1 right-1 outline-none cursor-default text-white', 'hover:bg-white hover:text-gray-900'), "aria-label": "Close" },
                    React.createElement(XIcon, { className: "h-4 w-4" })),
                React.createElement(Popover.Arrow, { className: "fill-white" })))));
    function clearFilter() {
        setIsPopoverContentVisible(false);
        props.onFilterCommitted(undefined);
    }
    function getInitialItemsAndCheckedStates(items) {
        var _a, _b;
        const initialFilteredValues = new Set((_b = (_a = props.initialFilter) === null || _a === void 0 ? void 0 : _a.values) !== null && _b !== void 0 ? _b : items.map((item) => item.value));
        return items.map((item) => (Object.assign(Object.assign({}, item), { checked: initialFilteredValues.has(item.value) })));
    }
    function onCheckedChange(itemIndex, checked) {
        setItemsAndCheckedStates(itemsAndCheckedStates.map((item, index) => (index !== itemIndex ? item : Object.assign(Object.assign({}, item), { checked }))));
    }
    function onClickApply() {
        const filter = {
            values: itemsAndCheckedStates.filter((item) => item.checked === true).map((item) => item.value),
        };
        setIsPopoverContentVisible(false);
        props.onFilterCommitted(filter);
    }
    function renderIcon() {
        return hasActiveFilter ? (React.createElement("span", { className: "hover:text-red-600", onClick: (event) => {
                clearFilter();
                event.stopPropagation();
            } },
            React.createElement(XCircleIcon, { className: "h-4 w-4" }))) : (React.createElement("span", null,
            React.createElement(PlusIcon, { className: "h-4 w-4" })));
    }
    function renderSelectAllCheckbox() {
        const currentNumberOfCheckedBoxes = itemsAndCheckedStates.filter((item) => item.checked === true).length;
        const selectAllCheckboxCheckedState = currentNumberOfCheckedBoxes === 0
            ? false
            : currentNumberOfCheckedBoxes === props.items.length
                ? true
                : 'indeterminate';
        return (React.createElement(Checkbox, { classNames: {
                checkbox: StyleUtils.mergeClassNames('bg-transparent text-white border-white'),
                label: 'text-white',
            }, checked: selectAllCheckboxCheckedState, labelElement: "Select all", onCheckedChange: (checked) => {
                setItemsAndCheckedStates(itemsAndCheckedStates.map((item) => (Object.assign(Object.assign({}, item), { checked }))));
            }, key: crypto.randomUUID() }));
    }
}
