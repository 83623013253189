import * as React from 'react';
import * as ApiUtils from 'ApiUtils';
import * as ClientHelloUtils from 'ClientHelloUtils';
import * as ResultUtils from 'Utils/ResultUtils';
import * as Tunnel from 'ApiContracts/subtrace/tunnel/tunnel';
/**
 * Returns a stateful variable that is:
 * - `true` if the user is logged in
 * - `false` if the user is not logged in
 * - `undefined` if we don't know yet.
 */
export function useIsLoggedIn() {
    const [isLoggedIn, setIsLoggedIn] = React.useState(undefined);
    React.useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        async function tryLogIn() {
            try {
                const response = await ApiUtils.get('/api/GetSelf', { signal });
                await ApiUtils.assertStatus(response, 200);
                setIsLoggedIn(true);
            }
            catch (error) {
                if (controller.signal.aborted) {
                    // Do nothing since the API call was aborted, likely because the user navigated back in their
                    // browser or reloaded the page.
                }
                else {
                    setIsLoggedIn(false);
                    // TODO: Setup logging/telemetry so we can look at cases like this, instead of logging to console.
                    // https://github.com/subtrace/monorepo/issues/21
                    console.log(`Failed to log in: Error ${error}`);
                }
            }
        }
        tryLogIn();
        return () => {
            controller.abort('Cleaning up effect: useIsLoggedIn');
        };
    }, []);
    return isLoggedIn;
}
/**
 * Returns a stateful variable that is:
 * - An initialized websocket that is ready to send queries to, if initialization succeeds
 * - An error string if the initialization fails
 * - `undefined` while the initialization is happening.
 *
 * Note that initialization in this case corresponds to both the HTTP API call to create the tunnel as well as the
 * exchange of the client/server hello messages.
 */
export function useInitializedWebSocket() {
    const [result, setResult] = React.useState(undefined);
    React.useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let webSocket;
        async function tryInitializeWebSocket() {
            const createTunnelRequest = {
                // TODO: Don't hardcode this, get it from the current project that the user is accessing.
                projectId: '9eda2a98-6006-4c7e-bfd5-904cae36c308',
                role: Tunnel.Role.SELECT,
            };
            let endpoint;
            try {
                const response = await ApiUtils.post('/api/CreateTunnel', createTunnelRequest, { signal });
                await ApiUtils.assertStatus(response, 200);
                const data = await response.json();
                endpoint = data.endpoint;
            }
            catch (error) {
                if (controller.signal.aborted) {
                    // Do nothing since the API call was aborted, likely because the user navigated back in their
                    // browser or reloaded the page.
                    return undefined;
                }
                else {
                    // TODO: Setup logging/telemetry so we can look at cases like this, instead of logging to console.
                    // https://github.com/subtrace/monorepo/issues/21
                    console.log(`Failed to create tunnel: ${error}`);
                    setResult(ResultUtils.failure(error instanceof Error ? error.message : JSON.stringify(error)));
                    return;
                }
            }
            webSocket = new WebSocket(endpoint);
            webSocket.binaryType = 'arraybuffer';
            webSocket.onopen = () => {
                webSocket === null || webSocket === void 0 ? void 0 : webSocket.send(ClientHelloUtils.create());
            };
            webSocket.onmessage = () => {
                // TODO: The server hello is currently empty, but we should perform an intersection with the fields
                // that it returns. This information should be returned along with the WebSocket.
                setResult(ResultUtils.success(webSocket));
            };
        }
        tryInitializeWebSocket();
        return () => {
            controller.abort('Cleaning up effect: useInitializedWebSocket');
            webSocket === null || webSocket === void 0 ? void 0 : webSocket.close();
        };
    }, []);
    return result;
}
