import { TimestampFilterKind } from 'TimestampFilter';
import * as Verify from 'Utils/Verify';
export function getFilterStringValues(filter) {
    if (!filter) {
        return {
            comparisonValue: '',
            lowerBound: '',
            upperBound: '',
        };
    }
    switch (filter.filterKind) {
        case TimestampFilterKind.Between:
            return {
                comparisonValue: '',
                lowerBound: filter.lowerBound.toString(),
                upperBound: filter.upperBound.toString(),
            };
        case TimestampFilterKind.After:
        case TimestampFilterKind.Before:
            return {
                comparisonValue: filter.filterValue.toString(),
                lowerBound: '',
                upperBound: '',
            };
        default:
            Verify.isNever(filter);
    }
}
