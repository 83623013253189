import * as RadixUICheckbox from '@radix-ui/react-checkbox';
import React from 'react';
import { CheckIcon, MinusIcon } from 'lucide-react';
import * as StyleUtils from 'Utils/StyleUtils';
export function Checkbox(props) {
    var _a, _b, _c;
    const idForCheckbox = crypto.randomUUID();
    return (React.createElement("div", { className: StyleUtils.mergeClassNames('flex items-center', (_a = props.classNames) === null || _a === void 0 ? void 0 : _a.root) },
        React.createElement(RadixUICheckbox.Root, { className: StyleUtils.mergeClassNames('border flex h-4 w-4 appearance-none items-center justify-center rounded-[4px] bg-white focus:shadow-[0_0_0_2px_black]', (_b = props.classNames) === null || _b === void 0 ? void 0 : _b.checkbox), checked: props.checked, onCheckedChange: props.onCheckedChange, id: idForCheckbox },
            React.createElement(RadixUICheckbox.Indicator, null,
                props.checked === 'indeterminate' && React.createElement(MinusIcon, { className: "h-3 w-3" }),
                props.checked === true && React.createElement(CheckIcon, { className: "h-3 w-3" }))),
        React.createElement("label", { className: StyleUtils.mergeClassNames('pl-2 text-[15px] leading-none', (_c = props.classNames) === null || _c === void 0 ? void 0 : _c.label), htmlFor: idForCheckbox }, props.labelElement)));
}
