import * as React from 'react';
import { MoveDownLeftIcon, MoveUpRightIcon } from 'lucide-react';
import * as CustomHooks from 'CustomHooks';
import * as ResultUtils from 'Utils/ResultUtils';
import * as Tunnel from 'ApiContracts/subtrace/tunnel/tunnel';
import * as UIQueryUtils from 'UIQueryUtils';
import * as VarintUtils from 'VarintUtils';
import { Checkbox } from 'DesignComponents/Checkbox';
import { Event as SubtraceEvent } from 'ApiContracts/subtrace/event/event';
import { HttpIncomingOutgoingCellRenderer } from 'HttpIncomingOutgoingCellRenderer';
import { HttpMethodName } from 'HttpMethodName';
import { LazyGrid } from 'LazyGrid';
import { MultiSelectFilterButton } from './MultiSelectFilterButton';
import { NumericalFilterButton } from 'NumericalFilterButton';
import { RequestDetailsSidePanel } from 'RequestDetailsSidePanel';
import { Spinner } from 'DesignComponents/Spinner';
import { TextFilterButton } from './TextFilterButton';
import { ZIndex } from './ZIndex';
import { TimestampFilterButton } from 'TimestampFilterButton';
export function AppContents() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    // TODO: Unify gridData and fetchError into an AsyncValue like interface
    const [gridData, setGridData] = React.useState(undefined);
    const [currentEvaluatingQuery, setCurrentEvaluatingQuery] = React.useState({
    // Empty query means that no filters have been applied
    });
    const [currentAppliedQuery, setCurrentAppliedQuery] = React.useState({
    // Empty query means that no filters have been applied
    });
    const [areAllColumnsShown, setAreAllColumnsShown] = React.useState(false);
    const [highlightNon200Rows, setHighlightNon200Rows] = React.useState(false);
    const [sidePanelState, setSidePanelState] = React.useState({ isOpen: false });
    const initializedWebSocket = CustomHooks.useInitializedWebSocket();
    const handleWebsocketMessage = React.useCallback((message) => {
        const result = Tunnel.Result.decode(new Uint8Array(message.data));
        if (result.tunnelError) {
            // TODO: Graceful error handling
            window.alert(result.tunnelError);
            return;
        }
        if (result.clickhouseError) {
            // TODO: Graceful error handling
            window.alert(result.clickhouseError);
            return;
        }
        let currentByteIndex = 0;
        const events = [];
        while (currentByteIndex < result.data.byteLength) {
            const [eventSize, bytesRead] = VarintUtils.readFromBuffer(result.data, currentByteIndex);
            currentByteIndex += bytesRead;
            events.push(SubtraceEvent.decode(result.data.slice(currentByteIndex, currentByteIndex + eventSize)));
            currentByteIndex += eventSize;
        }
        setGridData(events);
        setCurrentAppliedQuery(currentEvaluatingQuery);
        setCurrentEvaluatingQuery(undefined);
    }, [currentEvaluatingQuery]);
    React.useEffect(() => {
        if (initializedWebSocket && ResultUtils.isSuccess(initializedWebSocket) && currentEvaluatingQuery) {
            const webSocket = initializedWebSocket.value;
            const sqlQueryMessage = Tunnel.Query.encode({
                sqlStatement: UIQueryUtils.getSqlQueryString(currentEvaluatingQuery),
                tunnelQueryId: crypto.randomUUID(),
            }).finish();
            webSocket.onmessage = handleWebsocketMessage;
            webSocket.send(sqlQueryMessage);
        }
    }, [currentEvaluatingQuery, handleWebsocketMessage, initializedWebSocket]);
    if (initializedWebSocket && ResultUtils.isFailure(initializedWebSocket)) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null, "There was an error fetching the requests:"),
            React.createElement("div", null, initializedWebSocket.error)));
    }
    const basicModeFiltersAndAppliedStatus = [
        [
            React.createElement(TextFilterButton, { filterParameterName: "TLS server name", initialFilter: (_a = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.tlsServerNameFilter) !== null && _a !== void 0 ? _a : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.tlsServerNameFilter, key: "tlsServerNameFilter", onFilterCommitted: (filter) => onFilterCommitted('tlsServerNameFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.tlsServerNameFilter) !== undefined,
        ],
        [
            React.createElement(TextFilterButton, { filterParameterName: "Request path", initialFilter: (_b = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.pathFilter) !== null && _b !== void 0 ? _b : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.pathFilter, key: "pathFilter", onFilterCommitted: (filter) => onFilterCommitted('pathFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.pathFilter) !== undefined,
        ],
        [
            React.createElement(MultiSelectFilterButton, { filterParameterName: "Request method", initialFilter: (_c = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.methodNameFilter) !== null && _c !== void 0 ? _c : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.methodNameFilter, key: "methodNameFilter", items: getMethodNameFilterItems(), onFilterCommitted: (filter) => onFilterCommitted('methodNameFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.methodNameFilter) !== undefined,
        ],
        [
            React.createElement(MultiSelectFilterButton, { filterParameterName: "Response status", initialFilter: (_d = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.statusCodeFilter) !== null && _d !== void 0 ? _d : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.statusCodeFilter, items: getStatusCodeFilterItems(), key: "statusCodeFilter", onFilterCommitted: (filter) => onFilterCommitted('statusCodeFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.statusCodeFilter) !== undefined,
        ],
        [
            React.createElement(NumericalFilterButton, { filterParameterName: "Duration", initialFilter: (_e = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.durationFilter) !== null && _e !== void 0 ? _e : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.durationFilter, key: "durationFilter", onFilterCommitted: (filter) => onFilterCommitted('durationFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.durationFilter) !== undefined,
        ],
        [
            React.createElement(MultiSelectFilterButton, { filterParameterName: "Incoming/Outgoing", initialFilter: (_f = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.isOutgoingFilter) !== null && _f !== void 0 ? _f : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.isOutgoingFilter, key: "isOutgoingFilter", items: getIsOutgoingFilterItems(), onFilterCommitted: (filter) => onFilterCommitted('isOutgoingFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.isOutgoingFilter) !== undefined,
        ],
    ];
    const advancedModeFiltersAndAppliedStatus = [
        [
            React.createElement(TextFilterButton, { filterParameterName: "Event ID", initialFilter: (_g = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.eventIdFilter) !== null && _g !== void 0 ? _g : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.eventIdFilter, key: "eventIdFilter", onFilterCommitted: (filter) => onFilterCommitted('eventIdFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.eventIdFilter) !== undefined,
        ],
        [
            React.createElement(TimestampFilterButton, { filterParameterName: "Timestamp", initialFilter: (_h = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.timestampFilter) !== null && _h !== void 0 ? _h : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.timestampFilter, key: "timestampFilter", onFilterCommitted: (filter) => onFilterCommitted('timestampFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.timestampFilter) !== undefined,
        ],
        [
            React.createElement(TextFilterButton, { filterParameterName: "Type", initialFilter: (_j = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.typeFilter) !== null && _j !== void 0 ? _j : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.typeFilter, key: "typeFilter", onFilterCommitted: (filter) => onFilterCommitted('typeFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.typeFilter) !== undefined,
        ],
        [
            React.createElement(NumericalFilterButton, { filterParameterName: "PID", initialFilter: (_k = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.pidFilter) !== null && _k !== void 0 ? _k : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.pidFilter, key: "pidFilter", onFilterCommitted: (filter) => onFilterCommitted('pidFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.pidFilter) !== undefined,
        ],
        [
            React.createElement(TextFilterButton, { filterParameterName: "Host name", initialFilter: (_l = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.hostNameFilter) !== null && _l !== void 0 ? _l : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.hostNameFilter, key: "hostNameFilter", onFilterCommitted: (filter) => onFilterCommitted('hostNameFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.hostNameFilter) !== undefined,
        ],
        [
            React.createElement(TextFilterButton, { filterParameterName: "version", initialFilter: (_m = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.versionFilter) !== null && _m !== void 0 ? _m : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.versionFilter, key: "versionFilter", onFilterCommitted: (filter) => onFilterCommitted('versionFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.versionFilter) !== undefined,
        ],
        [
            React.createElement(TextFilterButton, { filterParameterName: "Client address", initialFilter: (_o = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.clientAddressFilter) !== null && _o !== void 0 ? _o : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.clientAddressFilter, key: "clientAddressFilter", onFilterCommitted: (filter) => onFilterCommitted('clientAddressFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.clientAddressFilter) !== undefined,
        ],
        [
            React.createElement(TextFilterButton, { filterParameterName: "Server address", initialFilter: (_p = currentEvaluatingQuery === null || currentEvaluatingQuery === void 0 ? void 0 : currentEvaluatingQuery.serverAddressFilter) !== null && _p !== void 0 ? _p : currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.serverAddressFilter, key: "serverAddressFilter", onFilterCommitted: (filter) => onFilterCommitted('serverAddressFilter', filter), zIndex: ZIndex.FilterCallout }),
            (currentAppliedQuery === null || currentAppliedQuery === void 0 ? void 0 : currentAppliedQuery.serverAddressFilter) !== undefined,
        ],
    ];
    const filterButtonsAndAppliedStatus = [
        ...basicModeFiltersAndAppliedStatus,
        ...(areAllColumnsShown ? advancedModeFiltersAndAppliedStatus : []),
    ];
    return (React.createElement("div", { className: "flex flex-col" },
        React.createElement("div", { className: "space-x-3 mb-3 mt-5 h-14 overflow-x-auto whitespace-nowrap" }, filterButtonsAndAppliedStatus
            // Prioritize buttons that have a filter (note that sort is guaranteed to be stable)
            .sort((left, right) => Number(right[1]) - Number(left[1]))
            .map(([node]) => node)),
        React.createElement(LazyGrid, { fallback: React.createElement("div", { className: "h-[500px] flex justify-center items-center" },
                React.createElement(Spinner, { className: "scale-[200%]" })), className: "h-[500px]", columnDefinitions: [
                { headerName: 'TLS server name', field: 'tlsServerName', width: 200 },
                { headerName: 'Request path', field: 'httpReqPath', width: 400 },
                { headerName: 'Request method', field: 'httpReqMethod', width: 200 },
                { headerName: 'Response status code', field: 'httpRespStatusCode', width: 250 },
                { headerName: 'Duration (ns)', field: 'httpDuration' },
                {
                    headerName: 'Incoming/Outgoing',
                    field: 'httpIsOutgoing',
                    cellDataType: 'boolean',
                    cellRenderer: HttpIncomingOutgoingCellRenderer,
                },
                ...(areAllColumnsShown
                    ? [
                        // TODO (sachin): TypeScript doesn't infer the most specific types, so I'm adding
                        // const assertions to these. See if we can get rid of them. This still type checks
                        // correctly and gives us the benefits of type safety so I'm fine leaving them as is
                        // for now.
                        { headerName: 'Event ID', field: 'eventId' },
                        {
                            headerName: 'Timestamp',
                            field: 'timestamp',
                            valueFormatter: (params) => {
                                return params.value
                                    ? new Date(Number(params.value / BigInt(1e6))).toISOString()
                                    : '';
                            },
                            width: 250,
                        },
                        { headerName: 'Type', field: 'type' },
                        { headerName: 'PID', field: 'pid' },
                        { headerName: 'Host name', field: 'hostname' },
                        { headerName: 'Version', field: 'httpVersion' },
                        { headerName: 'Client address', field: 'httpClientAddr' },
                        { headerName: 'Server address', field: 'httpServerAddr' },
                    ]
                    : []),
            ], onRowDoubleClicked: onRowDoubleClicked, rowClassRules: {
                'error-row': (params) => { var _a; return highlightNon200Rows && ((_a = params.data) === null || _a === void 0 ? void 0 : _a.httpRespStatusCode) !== 200; },
            }, rowData: gridData !== null && gridData !== void 0 ? gridData : [], rowHeight: 35 }),
        React.createElement("div", { className: "pt-2 h-10 self-start flex flex-row" }, currentEvaluatingQuery ? (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "text-white" }, "Evaluating\u00A0"),
            React.createElement("span", null,
                React.createElement(Spinner, null)))) : null),
        React.createElement(Checkbox, { checked: areAllColumnsShown, classNames: { checkbox: 'bg-transparent border-white text-white', label: 'text-white' }, onCheckedChange: (checkedState) => setAreAllColumnsShown(!!checkedState), labelElement: "Show all columns" }),
        React.createElement(Checkbox, { checked: highlightNon200Rows, classNames: { root: 'mt-3', checkbox: 'bg-transparent border-white text-white', label: 'text-white' }, onCheckedChange: (checkedState) => setHighlightNon200Rows(!!checkedState), labelElement: "Highlight rows that don't have a 200 response status code" }),
        sidePanelState.isOpen ? (React.createElement(RequestDetailsSidePanel, { areAllColumnsShown: areAllColumnsShown, rowData: sidePanelState.rowData, closeSidePanel: () => setSidePanelState({ isOpen: false }) })) : null));
    function getIsOutgoingFilterItems() {
        return [
            {
                displayElement: (React.createElement("span", { className: "flex flex-row items-center" },
                    React.createElement(MoveUpRightIcon, { size: 20 }),
                    React.createElement("span", null, "Outgoing"))),
                itemKey: 'true',
                value: true,
                itemCount: gridData === null || gridData === void 0 ? void 0 : gridData.filter((event) => event.httpIsOutgoing === true).length,
            },
            {
                displayElement: (React.createElement("span", { className: "flex flex-row items-center" },
                    React.createElement(MoveDownLeftIcon, { size: 20 }),
                    React.createElement("span", null, "Incoming"))),
                itemKey: 'false',
                value: false,
                itemCount: gridData === null || gridData === void 0 ? void 0 : gridData.filter((event) => event.httpIsOutgoing === false).length,
            },
            {
                displayElement: '(null)',
                itemKey: '(null)',
                value: null,
                itemCount: gridData === null || gridData === void 0 ? void 0 : gridData.filter((event) => event.httpIsOutgoing === undefined).length,
            },
        ];
    }
    function getMethodNameFilterItems() {
        return Object.values(HttpMethodName).map((method) => ({
            displayElement: method,
            itemKey: method,
            value: method,
            itemCount: gridData === null || gridData === void 0 ? void 0 : gridData.filter((event) => event.httpReqMethod === method).length,
        }));
    }
    function getStatusCodeFilterItems() {
        if (!gridData) {
            return [];
        }
        const countsByStatusCode = {};
        for (const { httpRespStatusCode: statusCode } of gridData) {
            if (!statusCode) {
                continue;
            }
            if (!(statusCode in countsByStatusCode)) {
                countsByStatusCode[statusCode] = 0;
            }
            countsByStatusCode[statusCode]++;
        }
        return Object.entries(countsByStatusCode).map(([code, count]) => ({
            displayElement: code,
            itemKey: code,
            value: parseInt(code),
            itemCount: count,
        }));
    }
    function onFilterCommitted(filterKey, filter) {
        setCurrentEvaluatingQuery(Object.assign(Object.assign(Object.assign({}, currentAppliedQuery), currentEvaluatingQuery), { [filterKey]: filter }));
    }
    function onRowDoubleClicked(event) {
        if (event.data) {
            setSidePanelState({ isOpen: true, rowData: event.data });
        }
        else {
            // Not sure why this would happen, but log just in case
            console.log(event);
        }
    }
}
