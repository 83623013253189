import * as ResultUtils from 'Utils/ResultUtils';
export function parse(value) {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
        return ResultUtils.success(new Date(value));
    }
    else {
        return ResultUtils.failure('Invalid date');
    }
}
