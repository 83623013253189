import React from 'react';
import * as StyleUtils from 'Utils/StyleUtils';
import { Spinner } from './Spinner';
export function Button(props) {
    var _a;
    return (React.createElement("button", { "aria-label": (_a = props.ariaLabel) !== null && _a !== void 0 ? _a : props.label, className: StyleUtils.mergeClassNames('px-4 py-2 outline outline-2 outline-sky-900/60 text-sm text-white font-medium rounded-md bg-sky-700/95 hover:bg-sky-700 group', 'disabled:bg-slate-600 disabled:outline-none', props.className), disabled: props.disabled, onClick: props.onClick },
        React.createElement("span", { className: "flex justify-center" },
            props.showSpinner ? React.createElement(Spinner, { className: "mr-[2px]" }) : null,
            props.label,
            props.showArrow ? (React.createElement("span", { className: StyleUtils.mergeClassNames('inline-block ml-2 ', {
                    'transition ease-in-out group-hover:translate-x-[2px]': !props.disabled,
                }) }, "\u2192")) : null)));
}
